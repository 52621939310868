export const generatePassword = () => {
  const length = 8;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset?.length);
    password += charset[randomIndex];
  }
  return password;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  // const isString = matchIsString(text);
  return isNumber;
}
