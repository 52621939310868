export const answerKeySvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      opacity="0.989"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.62109 0.847169C9.26959 0.838052 13.918 0.847169 18.5664 0.874512C20.5242 2.6317 22.4656 4.40904 24.3906 6.20654C24.4816 12.7829 24.4998 19.3636 24.4453 25.9487C24.2539 26.5047 23.8802 26.8784 23.3242 27.0698C17.0898 27.1063 10.8555 27.1063 4.62109 27.0698C4.0651 26.8784 3.69141 26.5047 3.5 25.9487C3.46354 17.9644 3.46354 9.97998 3.5 1.99561C3.70155 1.43866 4.07524 1.05585 4.62109 0.847169ZM5.05859 1.72217C9.34243 1.72217 13.6263 1.72217 17.9102 1.72217C17.901 3.08948 17.9102 4.45666 17.9375 5.82373C18.1289 6.37974 18.5026 6.75342 19.0586 6.94482C20.5715 6.97217 22.0845 6.9813 23.5977 6.97217C23.6068 13.1519 23.5977 19.3315 23.5703 25.5112C23.5248 25.9214 23.2969 26.1493 22.8867 26.1948C16.851 26.249 10.8171 26.2307 4.78516 26.1401C4.5181 26.0163 4.38138 25.8067 4.375 25.5112C4.33854 17.8185 4.33854 10.1258 4.375 2.43311C4.43037 2.02238 4.65823 1.7854 5.05859 1.72217ZM18.7852 2.37842C20.1412 3.59759 21.4902 4.82806 22.832 6.06982C21.7201 6.1063 20.6081 6.1063 19.4961 6.06982C19.0859 6.02427 18.8581 5.79639 18.8125 5.38623C18.7852 4.38379 18.776 3.38119 18.7852 2.37842Z"
      fill="#480D9B"
    />
    <path
      opacity="0.982"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9336 3.47314C7.20978 3.46403 8.48581 3.47314 9.76172 3.50049C10.099 3.61898 10.3268 3.84684 10.4453 4.18408C10.4818 5.46012 10.4818 6.73618 10.4453 8.01221C10.3268 8.34946 10.099 8.57729 9.76172 8.6958C8.4857 8.73228 7.20962 8.73228 5.9336 8.6958C5.59634 8.57729 5.36849 8.34946 5.25 8.01221C5.21354 6.73618 5.21354 5.46012 5.25 4.18408C5.37869 3.84586 5.60656 3.60888 5.9336 3.47314ZM6.31641 4.34814C6.2265 4.39279 6.16274 4.46571 6.125 4.56689C6.07168 5.62909 6.08995 6.68642 6.17969 7.73877C6.2207 7.773 6.26631 7.80035 6.31641 7.8208C6.82681 7.82993 7.33726 7.83901 7.84766 7.84814C9.47007 8.30385 10.0534 7.72056 9.59766 6.09814C9.60548 5.54607 9.57813 4.99921 9.51563 4.45752C9.47461 4.42329 9.429 4.39595 9.37891 4.37549C8.35822 4.34815 7.33742 4.33903 6.31641 4.34814Z"
      fill="#480D9B"
    />
    <path
      opacity="0.993"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5655 7.84718C15.2113 7.83804 18.8572 7.84718 22.503 7.87452C22.6679 7.95814 22.7408 8.09486 22.7217 8.28468C22.7408 8.4745 22.6679 8.61121 22.503 8.69483C18.8571 8.73131 15.2113 8.73131 11.5655 8.69483C11.4005 8.61121 11.3276 8.4745 11.3467 8.28468C11.3348 8.08966 11.4078 7.94386 11.5655 7.84718Z"
      fill="#480D9B"
    />
    <path
      opacity="0.965"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9336 11.3472C7.20978 11.338 8.48581 11.3472 9.76172 11.3745C10.2009 11.5687 10.447 11.906 10.5 12.3862C10.6933 12.1747 10.9029 11.9832 11.1289 11.812C11.5178 11.6964 11.7548 11.8331 11.8398 12.2222C11.7996 12.3338 11.754 12.4432 11.7031 12.5503C11.3021 12.9513 10.901 13.3524 10.5 13.7534C10.4818 14.4644 10.4635 15.1753 10.4453 15.8862C10.3268 16.2235 10.099 16.4513 9.76172 16.5698C8.4857 16.6063 7.20962 16.6063 5.9336 16.5698C5.59634 16.4513 5.36849 16.2235 5.25 15.8862C5.21354 14.6102 5.21354 13.3341 5.25 12.0581C5.37869 11.7199 5.60656 11.4829 5.9336 11.3472ZM6.31641 12.2222C6.2265 12.2668 6.16274 12.3398 6.125 12.4409C6.07168 13.5031 6.08995 14.5604 6.17969 15.6128C6.2207 15.647 6.26631 15.6744 6.31641 15.6948C6.82681 15.704 7.33726 15.713 7.84766 15.7222C8.39973 15.73 8.9466 15.7027 9.48828 15.6401C9.52252 15.5991 9.54986 15.5535 9.57031 15.5034C9.60679 15.23 9.60679 14.9566 9.57031 14.6831C9.34123 14.9766 9.05866 15.1954 8.72266 15.3394C8.61104 15.2991 8.50167 15.2536 8.39453 15.2026C8.09375 14.9019 7.79297 14.6011 7.49219 14.3003C7.44127 14.1932 7.39572 14.0838 7.35547 13.9722C7.48196 13.5378 7.7554 13.4193 8.17578 13.6167C8.33984 13.7808 8.50391 13.9448 8.66797 14.1089C8.70445 14.1454 8.74087 14.1454 8.77734 14.1089C9.04165 13.8446 9.30601 13.5802 9.57031 13.3159C9.62002 12.9817 9.60181 12.6536 9.51563 12.3316C9.47461 12.2973 9.429 12.27 9.37891 12.2495C8.35822 12.2222 7.33742 12.213 6.31641 12.2222Z"
      fill="#480D9B"
    />
    <path
      opacity="0.993"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5655 15.7222C15.2113 15.713 18.8572 15.7222 22.503 15.7495C22.6679 15.8331 22.7408 15.9699 22.7217 16.1597C22.7408 16.3495 22.6679 16.4862 22.503 16.5698C18.8571 16.6063 15.2113 16.6063 11.5655 16.5698C11.4005 16.4862 11.3276 16.3495 11.3467 16.1597C11.3348 15.9647 11.4078 15.8189 11.5655 15.7222Z"
      fill="#480D9B"
    />
    <path
      opacity="0.982"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9336 19.2222C7.20978 19.213 8.48581 19.2222 9.76172 19.2495C10.099 19.368 10.3268 19.5959 10.4453 19.9331C10.4818 21.2091 10.4818 22.4852 10.4453 23.7612C10.3268 24.0985 10.099 24.3263 9.76172 24.4448C8.4857 24.4813 7.20962 24.4813 5.9336 24.4448C5.59634 24.3263 5.36849 24.0985 5.25 23.7612C5.21354 22.4852 5.21354 21.2091 5.25 19.9331C5.37869 19.5949 5.60656 19.3579 5.9336 19.2222ZM6.31641 20.0972C6.2265 20.1418 6.16274 20.2148 6.125 20.3159C6.07168 21.3781 6.08995 22.4354 6.17969 23.4878C6.2207 23.522 6.26631 23.5494 6.31641 23.5698C6.82681 23.579 7.33726 23.588 7.84766 23.5972C9.47007 24.0529 10.0534 23.4696 9.59766 21.8472C9.60548 21.2951 9.57813 20.7482 9.51563 20.2066C9.47461 20.1723 9.429 20.145 9.37891 20.1245C8.35822 20.0972 7.33742 20.088 6.31641 20.0972Z"
      fill="#480D9B"
    />
    <path
      opacity="0.993"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5655 23.5972C15.2113 23.588 18.8572 23.5972 22.503 23.6245C22.6679 23.7081 22.7408 23.8449 22.7217 24.0347C22.7408 24.2245 22.6679 24.3612 22.503 24.4448C18.8571 24.4813 15.2113 24.4813 11.5655 24.4448C11.4005 24.3612 11.3276 24.2245 11.3467 24.0347C11.3348 23.8397 11.4078 23.6939 11.5655 23.5972Z"
      fill="#480D9B"
    />
  </svg>
);
